@import "src/static/styles/breakpoints";
@import "src/static/styles/figma-variables";

.payment-options {
    margin-top: $spacing * 2;
    width: 100%;

    .payment-methods {
        max-width: 520px;
    }
}