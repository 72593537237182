@import "src/static/styles/breakpoints";
@import "src/static/styles/figma-variables";

.history-details-contract{
    width: 100%;
    display: flex;
    flex-direction: column;

  @include smaller-than-1024{
      display: block;
      padding: 0px;

  }
.history-details-contract-item{
    margin-top: 16px;
    margin-bottom: 40px;
}
.left-column {
    display: flex;
    align-items: center;
}
.status {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}
    .details-header {
        display: flex;
        justify-content: space-between;
    }
}

.restaurant-details-wrapper {
    margin-top: 16px;

}

