@import "src/static/styles/figma-variables";
@import "src/static/styles/breakpoints";

.restaurant-header{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: $spacing*3;

  .image-circle{
    height: 76px;
    width: 76px;
    min-width: 40px;
    display: flex;

    img{
      height: 100%;
      width: 100%;
      object-fit: cover;
      border-radius: 60px;
    }
  }

  .restaurant-location{
    span+span{
      margin-left: $spacing*2;
    }
  }

  svg{
    vertical-align: center;
  }
}
