@import "src/static/styles/figma-variables";
@import "src/static/styles/breakpoints";


.unauthorized{
    display: flex;
    flex-wrap: nowrap;
    flex-basis: auto;
    flex-grow: 1;

    .left-banner{
        position: relative;
        min-width: 460px;
        min-height: 100%;
        background-image: url("../../static/images/left_banner.png");
        background-size: cover;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 60px;

        @include smaller-than-1024{
            display: none;
        }

        .overlay{
            background-color: $primary_black_overlay;
            position: absolute;
            width: 100%;
            height: 100%;
        }

        .partners{
            position: absolute;
            bottom: $spacing*4;
            width: 100%;

            .logos{
                opacity: 0.85;
                display: flex;
                justify-content: space-around;
            }
        }
    }
}
