@import "src/static/styles/figma-variables";
@import "src/static/styles/breakpoints";

.payment{
    display: flex;
    width: 100%;
    padding: $spacing 0;

    @include smaller-than-1024{
        flex-direction: column;
        padding: $spacing*2;
    }

    .item {
        @include smaller-than-1024{
            justify-content: space-between;
        }
    }

    &+.payment{
        border-top: 1px solid $additional_grey_4;
    }

    hr{
        margin: $spacing*1.5 $spacing*2;

        @include smaller-than-1024{
            display: none;
        }
    }
}
