@import "src/static/styles/breakpoints";
@import "src/static/styles/figma-variables";

.invite-table {
  &-header {
    background: $additional_grey_5;

    .MuiTableRow-root {
      .MuiTableCell-root {
        border-bottom: 0;
        line-height: 1rem;
        color: $additional_grey_7;
      }
    }
  }

  &-row {
    height:77px;

    .MuiTableCell-root {
      border-bottom: 1px solid $additional_grey_5;
    }

    .user-row{
      display: flex;
      justify-content: flex-start;

      .avatar{
        margin-right: 16px;
        img{
          width: 40px;
          height: 40px;
          border-radius: 30px;
          object-fit: cover;
        }

        .MuiSvgIcon-root{
          width: 40px;
          height: 40px;
        }
      }

      .info{
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      & + .user-row {
        margin-top: 24px;
      }
    }
  }
}
