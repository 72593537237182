@import "src/static/styles/figma-variables";
@import "src/static/styles/figma-variables";
@import "src/static/styles/breakpoints";

.header{
    box-shadow: 0 5px 15px 0 rgba(10, 25, 45, 0.05);
    z-index: 200;

    .header-desktop-wrapper{
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 80px;

        @include smaller-than-1024{
            padding: $spacing*2;
        }

        >*{
            flex: 1 1 0px;
        }

        .menu-wrapper{
            display: flex;
            justify-content: flex-end;
        }

        .nav-wrapper{
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            @include smaller-than-1024{
                 display: none;
            }

            a{
                margin: 0 20px;
                font-weight: 500;

                &.active{
                    .nav-item{
                        color: $primary_black;
                    }
                }

                .nav-item{
                    height: 100%;
                    line-height: 80px;
                }
            }
        }

    }


    .nav-wrapper-mob{
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        overflow: scroll;

        @include bigger-than-1024{
            display: none;

        }

        a{
            margin: 0 20px;
            font-weight: 500;

            &.active{
                .nav-item{
                    color: $primary_black;
                }
            }

            .nav-item{
                height: 100%;
                line-height: 80px;
            }
        }
    }

    .user-nav{
        width: 180px;
        display: flex;
        justify-content: space-around;

        @include smaller-than-1024{
            width: unset;
        }
    }
}
