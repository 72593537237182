@import "src/static/styles/figma-variables";
@import "src/static/styles/breakpoints";

#menuToggle {
    position: relative;
    -webkit-user-select: none;
    user-select: none;
    z-index: 2;


    input {
        display: block;
        width: 25px;
        height: 25px;
        position: absolute;
        top: -7px;
        left: -5px;
        cursor: pointer;
        opacity: 0; /* hide this */
        z-index: 2; /* and place it over the hamburger */
        -webkit-touch-callout: none;
    }

    #burger{
        /*
         * Just a quick hamburger
         */
        span {
            display: block;
            width: 18px;
            height: 2px;
            margin-bottom: 3px;
            position: relative;
            background: $additional_grey_2;
            z-index: 1;
            transform-origin: 0px 0px;
            transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0),
            background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0),
            opacity 0.55s ease;
        }

        span:nth-last-child(3) {
            transform-origin: 0% 0%;
        }

        span:nth-last-child(1) {
            transform-origin: 0% 100%;
        }
    }

    /*
     * Transform all the slices of hamburger
     * into a crossmark.
     */
    input:checked ~ #burger span:nth-last-child(3) {
        transform: rotate(45deg) translate(-3px, -2px);
    }

    /*
     * But let's hide the middle one.
     */
    input:checked ~ #burger span:nth-last-child(2) {
        opacity: 0;
        transform: rotate(0deg) scale(0.2, 0.2);
    }

    /*
     * Ohyeah and the last one should go the other direction
     */
    input:checked ~ #burger span:nth-last-child(1) {
        transform: rotate(-45deg) translate(0, -1px);
    }

    /*
     * Make this absolute positioned
     * at the top left of the screen
     */
    #menu {
        transition: opacity 0.3s ease;
        position: absolute;
        width: fit-content;
        top: 30px;
        right: 0;
        box-shadow: 0 10px 15px 0 rgba(10, 25, 45, 0.1);
        border-radius: 6px;
        background: $primary_white;
        list-style-type: none;
        -webkit-font-smoothing: antialiased;
        height: 0;
        opacity: 0;
        overflow: hidden;

        @include smaller-than-1024{
            top: 70px;
            position: fixed;
            width: 100%;
            height: 0;

            .menu-item{
                padding: 2*$spacing 2*$spacing;
            }
        }

        .menu-item{
            cursor: pointer;
            width: 100%;
            padding: $spacing 2*$spacing;
            display: flex;
            flex-wrap: nowrap;
            white-space: nowrap;
            color: $primary_black;

            &:hover{
                background-color: $additional_grey_5;
                color: $primary_black;
            }
        }
    }

    input:checked ~ #menu {
        transition: opacity 0.3s ease;
        height: auto;
        opacity: 1;
    }
}
