@import "src/static/styles/breakpoints";
@import "src/static/styles/figma-variables";

.login{
    min-height: 95vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @include smaller-than-1024{
        padding: 15px;
    }

    form{
        width: 100%;
        max-width: 500px;
    }

    &-footer {
        display: flex;
        justify-content: center;
    }
}


