@import "src/static/styles/figma-variables";
@import "src/static/styles/breakpoints";

.review{
    display: flex;
    padding: $spacing*2 0;
    &+.review{
        border-top: 1px solid $additional_grey_4;
    }

    @include smaller-than-1024{
        flex-direction: column;
    }

    >div{
        display: flex;
    }
}

.review-form{
    .input-wrapper{
        flex: 1;
        padding-left: 4*$spacing;

        @include smaller-than-1024{
            width: 100%;
            padding-left: 0;
        }

        .MuiOutlinedInput-inputMultiline{
            height: 48px !important;
        }
    }

    .rating-wrapper{
        background-color: $additional_grey_5;
        border-radius: 6px;
        width: 300px;
        max-width: 100%;
        padding: 2*$spacing;
        display: flex;
        flex-direction: column;
        align-items: center;

        @include smaller-than-1024{
            width: 100%;
        }
    }

    .MuiFormHelperText-contained{
        margin-left: 0;
        margin-right: 0;
    }
}
