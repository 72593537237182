@import "src/static/styles/figma-variables";
@import "src/static/styles/breakpoints";

.page.gig-edit{
    form{
        width: 100%;
        display: flex;

        @include smaller-than-1024{
            display: block;
        }

        .MuiFormControl-fullWidth{
            width: 455px;
            max-width: 100%;
        }

        .main-info{
            flex: 0 0 58%;
            padding: $spacing*4;

            @include smaller-than-1024{
                flex: unset;
            }



            .datetime-row{
                display: flex;

                @include smaller-than-1024{
                    flex-direction: column;

                    >div+div{
                        margin-left: 0 !important;

                    }
                }

                >div+div{
                    margin-left: $spacing;
                }

                .time-wrapper{
                    display: flex;

                    @include smaller-than-1024{
                        margin-top: $spacing*3;
                    }

                    >.MuiFormControl-root {
                        flex: 1;
                    }

                    .time-input{
                        min-width: 150px;

                        @include smaller-than-1024{
                            min-width: unset;
                        }
                    }
                }

                .text-element{
                    vertical-align: middle;
                    line-height: 48px;
                    white-space: nowrap;
                }
            }
        }

        .prices {
            flex: 0 0 28%;
            margin-left: $spacing*3;
            padding: $spacing*4;
            max-width: 410px;

            @include smaller-than-1024{
                flex: unset;
                margin-left: 0;
            }

            .actions {
                margin-top: auto;
            }
        }

        .time-select-wrapper {
            max-width: 100%;
        }
    }

}
