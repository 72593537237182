@import "src/static/styles/figma-variables";
@import "src/static/styles/breakpoints";

.banner {
    background: $additional_grey_5;
    margin-left: $spacing*2;
    max-width: 750px;
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    .text-block {
        display: flex;
        flex-direction: column;
        text-align: center;
        min-width: 460px;

        &-header {
            margin-bottom: $spacing*3;
        }

        &-footer {
            margin-top: $spacing*3;
        }

        .buttons {
            display: flex;
            justify-content: space-between;
            margin: $spacing*2 0;
        }
    }
}



