@import "src/static/styles/breakpoints";
@import "src/static/styles/figma-variables";

.invites{
    display: flex;
    flex-direction: column;
    margin-top: $spacing*2;

    @include smaller-than-1024{
        flex-direction: column;
    }

    .invite-form{
        .invite-input {
            display: flex;

            @include smaller-than-1024{
                flex-direction: column;
                align-items: unset;
            }

            &-text {
                width: 450px;
                @include smaller-than-1024{
                    width: 100%;
                }
            }

            &-button {
                width: 220px;
                margin-top: 2px;

                @include smaller-than-1024{
                    width: 100%;
                    margin: 0;
                    margin-top: 0px;
                }
            }
        }
        .invites-warning{
            display: flex;
            justify-content: flex-start;
            padding: 10px 0;
        }
    }

    .managers{
        margin-right: $spacing*4;
        padding: $spacing*2;
        height: fit-content;

        @include smaller-than-1024{
            margin-right: 0;
        }

        .user-row{
            display: flex;
            justify-content: flex-start;

            .avatar{
                margin-right: 16px;
                img{
                    width: 45px;
                    height: 45px;
                    border-radius: 30px;
                    object-fit: cover;
                }

                .MuiSvgIcon-root{
                    width: 45px;
                    height: 45px;
                }
            }

            .info{
                display: flex;
                flex-direction: column;
                justify-content: center;
            }

            & + .user-row {
                margin-top: 24px;
            }
        }
    }
}

.managers-row {
    display: flex;
    justify-content: space-between;
}
