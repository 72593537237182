@import "src/static/styles/figma-variables";
@import "src/static/styles/breakpoints";

.restaurant-photos-list-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: $spacing;
    border-radius: 4px;
    background-color: white;
    cursor: pointer;
    max-width: 100%;

    &.dragging,
    &:not(.dragging-disabled):not(.covered):hover {
        background-color: $additional_grey_5;
        
        .info {
            color: $additional_grey_1;
        }

        .drag-action {
            display: block !important;
            fill: $additional_grey_2;
        }
    }
    
    &.dragging-disabled {
        cursor: unset;
        opacity: 0.7;
    }

    &.covered {
        cursor: unset;
        $covered-border: 1px;
        border: $covered-border solid $primary_neon;
        padding: $spacing - $covered-border;

        .covered-indicator {
            display: block !important;
        }
    }

    .image {
        flex: 0;

        img {
            width: 40px;
            height: 40px;
            border-radius: 4px;
            object-fit: cover;
        }
    }

    .info {
        flex: 1;
        margin: 0 $spacing * 2;
        color: $additional_grey_2;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .right-panel {
        flex: 0;
        display: flex;

        .indicators-panel {
            .indicator {
                align-items: center;
                display: none;
    
                &.covered-indicator {
                    margin-right: $spacing;
                }
            }
        }

        .actions-panel {
            .action {
                align-items: center;
                display: none;

                &.drag-action {
                    @include smaller-than-1024 {
                        display: block;
                        fill: $additional_grey_2;
                    }

                    width: 24px;
                    height: 24px;
                }
            }
        }
    }
}