.MuiDialog-container{
    form{
        width: 460px;
        max-width: 100%;

        .MuiButton-root{
            width: 30%;
        }
    }

    .MuiDialogActions-root{
        padding: $spacing * 2 $spacing*3;
        border-top: 1px solid $tile_border;
    }

    .datetime-row{
        display: flex;
        justify-content: space-between;

        >div{
            width: 48%;
        }
    }
}
