@import "src/static/styles/breakpoints";
@import "src/static/styles/figma-variables";

.invite-card {
  display: flex;
  margin: 15px 0;

  .badge {
    margin: 0;
  }

  &-info {
    margin-left: $spacing*2;
  }

  &--delete {
    margin: 0;
    padding: 0;
  }
}
