@mixin smaller-than-1024 {
    @media screen and (max-width: 1023px) {
        @content;
    }
}

@mixin bigger-than-1024 {
    @media screen and (min-width: 1024px) {
        @content;
    }
}
