@import "src/static/styles/figma-variables";
@import "src/static/styles/breakpoints";

.review{
  display: flex;
  flex-direction: column;

  padding: $spacing*2 0;
  &+.review{
    border-top: 1px solid $additional_grey_4;
  }

  >div{
    display: flex;
  }
}

.review-form{
  .input-wrapper{
    width: 100%;
    padding-left: 0;

    .MuiOutlinedInput-inputMultiline{
      height: 48px !important;
    }
  }

  .rating-wrapper{
    background-color: $additional_grey_5;
    border-radius: 6px;
    max-width: 100%;
    padding: 2*$spacing;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .info-wrapper{
      border: 1px solid $additional_grey_4;
      border-radius: 6px;
      max-width: 100%;
      padding: 2*$spacing;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
    }
  }

  .MuiFormHelperText-contained{
    margin-left: 0;
    margin-right: 0;
  }
}

.review-completed {
  @include smaller-than-1024{
    padding-top: 16px;
  }

  .feedback-wrapper{
    border: 1px solid $additional_grey_4;
    border-radius: 6px;
    max-width: 100%;
    padding: 2*$spacing;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .rating-wrapper {
      .numeric {
        vertical-align: super;
      }
    }
  }

  .MuiFormHelperText-contained{
    margin-left: 0;
    margin-right: 0;
  }


}
