@import "src/static/styles/figma-variables";
@import "src/static/styles/breakpoints";


.profile-wrapper{
    width:500px;
    @include  smaller-than-1024{
        min-width: 280px;
        width:100%;
    }

    .profile-info{
        text-align: left;

        .profile-info-row{
            padding: $spacing*2 0;
            border-bottom: 1px solid $additional_grey_4;

            &:last-child {
                border-bottom: none;
            }
        }
    }

    .profile-form {
        .edit-buttons {
            display: flex;
            justify-content: space-between;

            @include  smaller-than-1024{
                flex-direction: column;
                &>button:last-child {
                    margin-top: $spacing*2;
                }
            }

        &>button {
                @include  smaller-than-1024{
                    width:100%;
                }
            }
        }
    }
}

