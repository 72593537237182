@import "src/static/styles/figma-variables";
@import "src/static/styles/breakpoints";

.MoonlightersPage {
    width: 100%;
    padding-left: 20px;


    .content {
        .navigation {
            text-align: right;
        }

        .list {
            margin-top: 10px;
            background-color: #FFF;
            border: 1px solid #e0e0e0;
            min-height: 100px;
            border-radius: 8px;
        }
    }


}

.moonlighters-header {
    display: flex;
    align-items: baseline;
    gap: $spacing*2;
}

.filters {
    margin-top: 0;
    gap: $spacing;
    .select {
        border: 1px solid #DFE4ED;
        border-radius: 16px;
        padding: 5px 10px 5px 16px;

        .MuiFormControl-root {
            .Mui-Focused {
                .MuiInput-root {
                    .MuiInputBase-input {
                        background: #FFFFFF;
                    }
                }
            }

            .MuiInput-root {
                &:before {
                    border-bottom: 0;
                }

                &:after {
                    border-bottom: 0;
                }

                .MuiInputBase-input {
                    padding: 0 10px;
                    margin-right: 16px;
                }
            }
        }
    }
}

.search-input {
    display: flex;
    padding-bottom: $spacing*2;
    width: 20%;
}

.grid-wrapper {
    height: auto;
    width: 100%;
}

.rating {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: $spacing;
}

.avatar {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        width: 40px;
        height: 40px;
        border-radius: 30px;
        object-fit: cover;
    }

    .MuiSvgIcon-root{
        width: 40px;
        height: 40px;
    }
}

.block-cell {
    float: right;
}


.binded {
    display: flex;
    gap: $spacing;
}

.skills {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}



