$primary_black: #0a192d;
$primary_black_overlay: rgba(10, 25, 45, 0.7);
$primary_neon: #0ed3c9;
$primary_pink: #ef2d56;
$primary_white: #ffffff;
$additional_grey_1: #353a46;
$additional_grey_2: #6b7583;
$additional_grey_3: #c6cdd6;
$additional_grey_4: #DFE4ED;
$additional_grey_5: #F3F5F9;
$additional_grey_6: #959DA9;
$additional_grey_7: #6C7684;
$additional_lavender: #bdc9e6;
$yellow: #ffe476;
$system_error: #f2544b;
$system_error_background: #ffe6e3;
$spark_main_white: #ffffff;
$gray_3: #828282;
$blue_3: #56ccf2;
$gray_1: #333333;
$primary_500: #6200ee;
$gray_5: #e0e0e0;
$tile_border: #ECEFF5;
$spacing: 8px;
$light_neon: #E7FCFB;
$dark_neon: #009688;

.additional_grey_3{
    color: $additional_grey_3;
}

.additional_grey_2{
    color: $additional_grey_2;
}

.additional_grey_1{
    color: $additional_grey_1;
}

.additional_grey_4{
    color: $additional_grey_4;
}

.additional_grey_5{
    color: $additional_grey_5;
}

.additional_grey_6{
    color: $additional_grey_6;
}

.additional_grey_7{
    color: $additional_grey_7;
}

.system_error{
    color: $system_error;
}

.yellow{
    color: $yellow;
}

.primary_neon{
    color: $primary_neon;
}

.primary_white{
    color: $primary_white;
}

.primary_black{
    color: $primary_black;
}
