@import "breakpoints";

.layout{
    .MuiButtonBase-root.MuiButton-containedSizeLarge{
        padding: 7px 21px;
    }

    .MuiInputBase-input{
        padding: 13.5px 14px;
    }

    .MuiInputLabel-outlined {
        transform: translate(14px, $spacing * 2) scale(1);
    }
}

header.MuiPaper-elevation4{
    box-shadow: 0 5px 15px 0 rgba(10, 25, 45, 0.05);
}


.MuiInputAdornment-positionEnd .MuiIconButton-root{
    padding: 0;
}

.MuiTab-wrapper{
    text-transform: none;
}

.MuiTabs-indicator{
    height: 4px !important;
}

.MuiTabs-root{
    border-bottom: 1px solid $additional_grey_4;
}

.MuiOutlinedInput-multiline{
    padding: 0 !important;
}


.MuiButtonBase-root.warning{
    background-color: $system_error;
}

.react-tel-input .form-control{
    width: 100%;
    height: 46px;
}

.MuiDialogContent-root{
    @include smaller-than-1024{
        .actions{
            display: flex;
            flex-direction: column;

            &>*{
                width: 100% !important;
                margin-left: 0 !important;
                margin-right: 0 !important;
            }

            &>*+*{
                margin-top: $spacing;
            }
        }
    }
}

.MuiCollapse-entered{
    pointer-events: all !important;
}
