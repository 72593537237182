@import "src/static/styles/breakpoints";

.remove-overlay{
    position: relative;
    height: 100%;
    width: 100%;

    &:hover{
        .loader {
            visibility: visible;
        }
    }

    .loader{
        z-index: 100;
        position: absolute;
        visibility: hidden;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(0, 0, 0, 0.4);
        border-radius: 4px;

        @include smaller-than-1024{
            visibility: visible;
        }

        .remove-circle{
            width: 36px;
            height: 36px;
            border-radius: 36px;
            background-color: rgba(255, 255, 255, 0.8);
            display: flex;
            justify-content: center;
            align-items: center;

            &:hover {
                background-color: rgba(255, 255, 255, 1);
            }
        }

        button{
            background-color: rgba(255, 255, 255, 0.8);
        }
    }
}
