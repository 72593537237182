@import "src/static/styles/figma-variables";

.receipt{
    margin: 0 auto;
    width: 650px;
    max-width: 100%;

    .receipt-row{
        display: flex;
        justify-content: space-between;
        margin-bottom: $spacing;
        margin-top: $spacing;
    }
}

.page>.report{
    display: flex;

    .content {
        margin: 10px;
        padding: 10px;
        border-radius: 8px;
        background-color: #FFF;
    }


}

.report-wrapper {
    min-width: 500px;
    width: 500px;
}


