@import "src/static/styles/figma-variables";
@import "src/static/styles/breakpoints";

.ManagersPage {
    width: 100%;
    padding-left: 20px;

    .content {
        .navigation {
            text-align: right;
        }

        .list {
            margin-top: 10px;
            background-color: #FFF;
            border: 1px solid #e0e0e0;
            min-height: 100px;
            border-radius: 8px;
        }
    }
}

.manager-info {
    display: flex;
    justify-content: flex-start;
}

.manager-info-column {
    display: flex;
    flex-direction: column;
    min-width: 300px;

    &:last-child {
        border-bottom: none;
    }
}

.manager-accordion-item{
    display: flex;
    justify-content: space-between;
    padding: $spacing * 2.5 $spacing * 2 $spacing * 2.5;;
    box-shadow: 0px 0px 30px rgba(10, 25, 45, 0.07);
    border-radius: 6px;
    margin-bottom: $spacing * 2;
    gap: 1em;

    +.manager-accordion-item{
        padding-top: $spacing * 2;;
    }

    .manager-accordion-item-header{
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include smaller-than-1024{

        }

        .manager-accordion-item-header-info{
            display: flex;
            align-items: center;
            justify-content: flex-start;

            .image-circle{
                height: 40px;
                width: 40px;
                min-width: 40px;
                display: flex;

                img{
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                    border-radius: 60px;
                }
            }

            svg{
                vertical-align: center;
            }
        }

        .manager-accordion-item-header-actions{
            @include smaller-than-1024{
                align-self: center;
            }
        }
    }

    .tile{
        @include smaller-than-1024{
            width: 100% !important;
            margin-right: 0;
            margin-bottom: 0;
        }
    }

    .arrow-icon {
        background-color: $additional_grey_4;
        border-radius: $spacing * 2.5;
        width: $spacing * 4;
        height: $spacing * 4;
        vertical-align: middle;

        @include smaller-than-1024{
            background-color: transparent;
        }
    }

    .item-header {
        margin-left: $spacing * 1.5;

        @include smaller-than-1024{
            margin-left: 0;
            margin-right: $spacing;
        }
    }

    .left-side {
        display: flex;
        align-items: center;
    }

}

.manager-button {
    display: flex;
    justify-content: flex-end;
    margin: 10px 0;
    gap: 2em;
}

.center {
 align-items: center;
}


