@import "./colors.css";

body {
    color: var(--color-text-main);
    margin: 0;
    padding: 10px;
    width: 100%;
    background-color: #fff;
    font-size: 14px;

    h1 {
        padding: 0;
        margin: 0;
        font-weight: normal;
        font-size: 24px;
    }

    .link {
        color: var(--color-link-main);
        cursor: pointer;

        &:hover {
            color: var(--color-link-hover);
        }

        &:active {
            color: var(--color-link-active);
        }
    }

    /* ANTD */
    .ant-modal-header {
        background-color: var(--color-background-main);
    }
}
