@import "../../colors.css";

.EmployerRestaurantList {

    .EmployerRestaurantItem {
        margin: 8px 4px;
        padding: 10px;
        border: 1px solid var(--color-border-main);
        border-radius: 8px;
        cursor: pointer;

        &:hover {
            box-shadow: 0px 0px 4px var(--color-shadow-main);
            background: var(--color-background-main);
        }

        .name {
            font-size: 16px;
        }

        .contactInfo {
            margin-left: 20px;
            color: var(--color-text-description);
        }
    }
}