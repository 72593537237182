@import "../../colors.css";

.ContextLoader {
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%,-50%);

    background-color: var(--color-background-white);
    padding: 20px;
    border-radius: 8px;

    .content {
        display: flex;
        flex-direction: column;
        align-items: center;

        .progress {

            .ant-progress-text {
                font-size: 16px;
            }
        }

        .title {
            margin-top: 20px;
        }

        .modules {
            margin-top: 10px;
        }
    }
}
