@import "src/static/styles/figma-variables";

.autocomplete-dropdown-container{
    position: absolute;
    top: 100%;
    width: 100%;
    background-color: $primary_white;
    z-index: 2;


    .suggestion-item{
        padding: $spacing*1.5;
        border-radius: 6px;

        &.active{
            background-color: $additional_grey_5;
        }

        &:hover{
            background-color: $additional_grey_5;
        }
    }
}
