@import "src/static/styles/breakpoints";

.details-tabs {
 & .MuiTabs-scroller {
   .MuiTabs-flexContainer {
     .poppins {
       min-width:100px;
       padding: 6px 12px;
     }
   }
 }
}

.tab-body {
  flex: 1;

  @include bigger-than-1024 {
    position: relative;
    overflow-y: auto;
  }

  .reviews-list {
    @include bigger-than-1024 {
      position: absolute;
      //width: fill-available;
    }
  }
}
