.history {
  position: relative;
  display: flex;
  flex-direction: row;

  .page {
    flex-grow: 1;
    width: 100%;
  }

  .filters {
    margin-top: 0;
    .select {
      border: 1px solid #DFE4ED;
      border-radius: 16px;
      padding: 5px 10px 5px 16px;

      .MuiFormControl-root {
        .Mui-Focused {
          .MuiInput-root {
            .MuiInputBase-input {
              background: #FFFFFF;
            }
          }
        }

        .MuiInput-root {
          &:before {
            border-bottom: 0;
          }

          &:after {
            border-bottom: 0;
          }

          .MuiInputBase-input {
            padding: 0 10px;
            margin-right: 16px;
          }
        }
      }
    }
  }

  .list {
    width: 100%;
  }

  .expanded {
    -webkit-animation: containerGrow 0.2s ease-in alternate 1;
    -moz-animation: containerGrow 0.2s ease-in alternate 1;
    animation: containerGrow 0.2s ease-in alternate 1;
  }

  @keyframes containerGrow {
    from { padding-left: 72px; }
    to { padding-left: 248px; }
  }

  @keyframes containerShrink {
    from { padding-left: 248px; }
    to { padding-left: 72px; }
  }
}
