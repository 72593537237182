@import "src/static/styles/figma-variables";
@import "src/static/styles/breakpoints";

.restaurant-accordion-item{
    padding: $spacing * 2.5 $spacing * 2 $spacing * 2.5;;
    box-shadow: 0px 0px 30px rgba(10, 25, 45, 0.07);
    border-radius: 6px;
    margin-bottom: $spacing * 2;

    +.restaurant-accordion-item{
        padding-top: $spacing * 2;;
    }

    .restaurant-accordion-item-header{
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include smaller-than-1024{

        }

        .restaurant-accordion-item-header-info{
            display: flex;
            align-items: center;
            justify-content: flex-start;

            .image-circle{
                height: 40px;
                width: 40px;
                min-width: 40px;
                display: flex;

                img{
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                    border-radius: 60px;
                }
            }

            .restaurant-location{
                span+span{
                    margin-left: $spacing*2;
                }
            }

            svg{
                vertical-align: center;
            }
        }

        .restaurant-accordion-item-header-actions{
            @include smaller-than-1024{
                align-self: center;
            }
        }

    }

    .tile{
        @include smaller-than-1024{
            width: 100% !important;
            margin-right: 0;
            margin-bottom: 0;
        }
    }

    .arrow-icon {
        background-color: $additional_grey_4;
        border-radius: $spacing * 2.5;
        width: $spacing * 4;
        height: $spacing * 4;
        vertical-align: middle;

        @include smaller-than-1024{
            background-color: transparent;
        }
    }

    .item-header {
        margin-left: $spacing * 1.5;

        @include smaller-than-1024{
            margin-left: 0;
            margin-right: $spacing;
        }
    }

    .left-side {
        display: flex;
        align-items: center;
    }

}
