@import "src/static/styles/figma-variables";
@import "src/static/styles/breakpoints";

.gig{
    .restaurant-accordion-item{
        margin-top: $spacing*2;
        margin-bottom: $spacing*5;

        @include smaller-than-1024 {
            margin-bottom: 0;
        }

        .restaurant-accordion-item-header{
            @include smaller-than-1024 {
                display: flex;
                flex-direction: column;

                .restaurant-location{
                    span{
                        display: block;
                    }

                    span+span{
                        margin-left: 0;
                    }

                }
            }
        }

    }

    .gig-header {
        display: flex;
        justify-content: space-between;
    }

    .gig-info{
        //width: 520px;
        max-width: 100%;

        .stats{
            display: flex;
            justify-content: space-between;

            @include smaller-than-1024{
                flex-direction: column;
            }
    .timespan {
        display: flex;
        width: 100%;
        @include smaller-than-1024{
            flex-direction: column;
            .mdash {display: none;}
    }

}
            .stat-card+.stat-card{
                margin-left: $spacing*2;

                @include smaller-than-1024{
                    margin-left: 0;
                    margin-top: $spacing*2;
                }
            }

            .single{
                flex-grow: 1;
            }

            .double{
                flex-grow: 2;
            }

            .mdash{
                background-color: $additional_grey_4;
                height: 2px;
                flex: 1 0 auto;
                margin: 0 $spacing*3;
                margin-top: $spacing*6;
            }
        }
    }
}
