@import "src/static/styles/breakpoints";
@import "src/static/styles/figma-variables";

.application{
    position: relative;
    //border: 1px solid $tile_border;
    border-radius: 6px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: 0 0 30px 0 rgba(10, 25, 45, 0.07);

    &:hover{
        box-shadow: 0 0 30px 0 rgba(10, 25, 45, 0.15);
    }

    @include smaller-than-1024{
        width: 100% !important;
        margin-right: 0;
        box-shadow: 0 0 30px 0 rgba(10, 25, 45, 0.20);
    }

    .tile-content{
        min-height: calc(100% - 78px);
        padding: $spacing*2;

        .tile-content-header{
            display: flex;
            justify-content: space-between;

            .left-column{
                display: flex;
                justify-content: flex-start;
                text-align: left;

                .status{

                }
            }


        }

        .tile-content-body{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            min-height: calc(100% - 45px);

            .stats{
                display: flex;

                .stat+.stat{
                    margin-left: $spacing*3;
                }
            }
        }


    }

    .tile-actions{
        display: flex;
        justify-content: space-between;
        align-items: center;
        //border-top: 1px solid $tile_border;
        padding: $spacing*2;

        button{
            max-width: 40%;
        }

        .MuiSvgIcon-root{
            cursor: pointer;
        }

        .MuiSvgIcon-root+.MuiSvgIcon-root{
            margin-left: $spacing*2;
        }
    }

    .progressbar{
        //position: absolute;
        //bottom: 0;
        //left: 0;
        margin-top: 8px;
        height: 6px;
        width: 100%;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
        //background-color: $additional_grey_4;
    }
}

.confirmation{
    .MuiDialogContent-root{
        width: 500px;
        max-width: 100%;
    }
}

.right-column{
    display: flex;
    text-align: right;
    gap: $spacing;

    @include smaller-than-1024{
        flex-direction: column;
    }
}

.blur{
    filter: blur(2.7px);
}
