@keyframes pulsation {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}


.pulsate {
    animation: pulsation 2000ms  infinite;
}
