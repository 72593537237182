@import "src/static/styles/figma-variables";
@import "src/static/styles/breakpoints";


.page.onboarding{
    margin: 0 auto;
    width: 800px;
    max-width: 100%;

    form{
        width: 100%;

        @include smaller-than-1024{
            display: block;
        }

        .main-info{
        }

        .form-control{
            width: 100%;
            height: 46px;
        }

        .gallery{
            .dropzone{
                width: 100%;
                border-radius: 4px;
                border: 1px dashed $additional_grey_3;
                background-color: $additional_grey_5;
                height: 400px;
                min-height: 120px;
                max-width: 100%;
                display: flex;
                flex-wrap: wrap;

                .empty-dropzone-text{
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;
                    align-items: center;
                    width: 100%;
                    min-height: 100%;
                    padding: $spacing;
                    text-align: center;
                }

                .overlay-loader{
                    width: 125px;
                    height: 104px;
                    margin: $spacing;
                    border-radius: 4px;

                    img{
                        height: 100%;
                        width: 100%;
                        object-fit: cover;
                        border-radius: 4px;
                    }

                    .badge{
                        z-index: 101;
                        width: fit-content;
                        position: absolute;
                        top: 5px;
                        left: 5px;
                        margin-left: 0;
                    }
                }
            }

            .photo-manager{
                .photo-row{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    border-radius: 4px;
                    padding: $spacing;
                    border: 1px solid transparent;
                    max-width: 100%;

                    .cover-bage{
                        display: none;
                    }

                    &:hover{
                        background-color: $additional_grey_5;
                        color: $additional_grey_1;

                        .right-column{
                            button{
                                display: block;
                            }
                        }
                    }

                    &.cover{
                        border: 1px solid $primary_neon;
                        box-sizing: border-box;

                        .cover-bage{
                            display: block;
                        }

                        &:hover{
                            background-color: inherit;

                            .right-column{
                                button{
                                    display: none;
                                }
                            }
                        }
                    }

                    .left-column{
                        display: flex;
                        align-items: center;
                        max-width: calc(100% - 132px);

                        .photo{
                            width: 40px;
                            height: 40px;
                            border-radius: 4px;
                            flex-shrink: 0;

                            img{
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                            }
                        }

                        .filename{
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            max-width: 100%;
                        }
                    }

                    .right-column{
                        flex: 0 0 auto;

                        button{
                            display: none;
                            background-color: $primary_white;
                            border-color: $additional_grey_4;
                        }
                    }
                }
            }

            .actions{
                display: flex;
                justify-content: flex-end;

                button{
                    max-width: 25%;
                }
            }
        }
    }

    .map-wrapper{
        height: 160px;
        width: 100%;
        border: 1px solid $additional_grey_3;
        border-radius: 4px;
    }

    .employer-info-wrapper {
        .profile-info{
            text-align: left;

            .profile-info-row{
                padding: $spacing*2 0;

                &:last-child {
                    border-bottom: none;
                }
            }
        }

        .profile-form {
            .btns {
                display: flex;
                justify-content: space-between;
                flex-direction: row-reverse;

                @include  smaller-than-1024{
                    flex-direction: column;
                    &>button:last-child {
                        margin-top: $spacing*2;
                    }
                }

                &>button {
                    @include  smaller-than-1024{
                        width:100%;
                    }
                }
            }
        }
    }

    .payment-tip {
        background-color: $additional_grey_5;
        padding: $spacing * 2;
        border-radius: 6px;
    }
}
