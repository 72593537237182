.main{
    display: flex;
    flex-direction: column;

    .content{
        min-height: calc(100vh - 412px);
        display: flex;
        
        > * {
            flex: 1;
        }
    }
}
