@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

@import 'figma-variables';
@import 'mui-overrides';
@import 'breakpoints';
@import 'forms';


@font-face {
    font-family: 'TT Norms';
    src: url('../fonts/TTNorms-Bold.eot');
    src: local('TT Norms Bold'), local('TTNorms-Bold'),
    url('../fonts/TTNorms-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/TTNorms-Bold.woff2') format('woff2'),
    url('../fonts/TTNorms-Bold.woff') format('woff'),
    url('../fonts/TTNorms-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'TT Norms';
    src: url('../fonts/TTNorms-Light.eot');
    src: local('TT Norms Light'), local('TTNorms-Light'),
    url('../fonts/TTNorms-Light.eot?#iefix') format('embedded-opentype'),
    url('../fonts/TTNorms-Light.woff2') format('woff2'),
    url('../fonts/TTNorms-Light.woff') format('woff'),
    url('../fonts/TTNorms-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'TT Norms';
    src: url('../fonts/TTNorms-Regular.eot');
    src: local('TT Norms Regular'), local('TTNorms-Regular'),
    url('../fonts/TTNorms-Regular.eot?#iefix') format('embedded-opentype'),
    url('../fonts/TTNorms-Regular.woff2') format('woff2'),
    url('../fonts/TTNorms-Regular.woff') format('woff'),
    url('../fonts/TTNorms-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

html{
    min-width: 100%;
}

body{
    font-family: Roboto;
    padding: 0;
    margin: 0;
    min-width: 100%;

    .roboto{
        font-family: 'Roboto', sans-serif;
    }

    .poppins{
        font-family: 'Poppins', sans-serif;
        font-weight: 400;

        &.bold{
            font-weight: 600;
        }
    }

    button{
        font-family: 'Poppins', sans-serif;
        font-weight: 400;
        text-wrap: none;
        white-space: nowrap;
    }

    .relative{
        position: relative;
    }

    a{
        color: $primary_pink;

        &:hover{
            color: $primary_pink;
        }
    }

    h1{
        font-size: 34px;
        line-height: 51px;
        color: $primary_black;


        @include smaller-than-1024{
            font-size: 28px;
            line-height: 42px;
        }
    }

    h2{
        font-size: 24px;
        line-height: 36px;
        //color: $primary_white;

        @include smaller-than-1024{
            font-size: 18px;
            line-height: 27px;
        }
    }

    .description{
        font-size: 16px;
        line-height: 24px;
        color: $additional_grey_2;

        @include smaller-than-1024{
            font-size: 14px;
            line-height: 21px;
        }
    }


    .layout{
        min-height: 100vh;
        min-width: 100%;
    }


    .app{
        &_header {
            display: grid;
            grid-template-columns: auto 400px;
            gap: 10px;
            align-items: flex-start;

            .title {
                font-size: 26px;
            }
        }
    }

    .fs-48{
        font-size: 48px;
        line-height: 72px;
    }

    .fs-34{
        font-size: 34px;
        line-height: 51px;
        vertical-align: middle;
    }

    .fs-32{
        font-size: 32px;
        line-height: 48px;
    }

    .fs-28{
        font-size: 28px;
    }

    .fs-24{
        font-size: 24px;
    }

    .fs-22{
        font-size: 20px;
    }

    .fs-20{
        font-size: 20px;
    }

    .fs-18{
        font-size: 18px;
        line-height: 27px;
    }

    .fs-16{
        font-size: 16px;
        line-height: 24px;
    }

    .fs-15{
        font-size: 15px;
    }

    .fs-14{
        font-size: 14px;
        line-height: 21px;
    }

    .fs-12{
        font-size: 12px;
        line-height: 18px;
    }

    .fs-11{
        font-size: 11px;
    }

    .fs-10{
        font-size: 10px;
    }

    .fs-8{
        font-size: 8px;
    }

    .fs-6{
        font-size: 6px;
    }

    .z-0{
        z-index: 0;
    }

    .z-1{
        z-index: 1;
    }

    .bold{
        font-weight: 700;
    }

    .italic{
        font-style: italic;
    }


    .w-40{
        width: 40%;
    }

    .w-20{
        width: 20%;
    }

    .lh-46{
        line-height: 46px;
    }
    .lh-48{
        line-height: 48px;
    }

    --scrollbar-thumb-padding: 10px;
    --scrollbar-thumb-width: 4px;
    --scrollbar-thumb-border-radius: 15px;

    .scrollable {
        &::-webkit-scrollbar {
            width: calc(var(--scrollbar-thumb-padding) * 2 + var(--scrollbar-thumb-width));
        }

        &::-webkit-scrollbar-track {
            background-color: transparent;
        }

        &::-webkit-scrollbar-thumb {
            width: var(--scrollbar-thumb-width);
            background-color: var(--color-scrollbar);
            background-clip: content-box;
            border: var(--scrollbar-thumb-padding) solid transparent;
            border-radius: var(--scrollbar-thumb-border-radius);
        }
    }
}

.scroll-lock {
    overflow: hidden;
}

.uppercase{
    text-transform: uppercase;
}

.page{
    padding: $spacing*4 $spacing*10 $spacing*8 $spacing*10;

    @include  smaller-than-1024{
        padding: $spacing*2;
    }

    .page-header{
        font-size: 34px;
        line-height: 51px;
        vertical-align: middle;

        @include smaller-than-1024{
            font-size: 18px;
            line-height: 27px;
            vertical-align: middle;
            font-weight: 600;
            }
        .status-icon{
            margin-right: $spacing*2;
        }
    }

    .page-header-with-actions{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: center;
    }



    .filters{
        margin: $spacing*3 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-height: 45px;

        >span{
            line-height: 46px;
            vertical-align: middle;
            color: $additional_grey_2;
        }

        .actions{
            margin: 0;
        }
    }
}

.overflow-scroll {
    max-height: 100vh;
    overflow-y: auto;
    width: 100%;
}

.actions{
    margin: $spacing*3 0;
    display: flex;
    justify-content: flex-end;

    button+button{
        margin-left: $spacing*2;
    }
}

.tile-list{
    margin-top: $spacing*2;
    display: flex;
    flex-wrap: wrap;

    @include smaller-than-1024{
        margin-top: $spacing*2.5;
    }

    >a{
        display: block;
        margin: $spacing/2 $spacing*3 $spacing/2 0;
        @include smaller-than-1024{
            width: 100% !important;
            margin-right: 0;
        }
    }

    .tile{
        position: relative;
        //border: 1px solid $tile_border;
        border-radius: 6px;
        width: 420px;
        max-width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border: 1px solid $additional_grey_4;

        &:hover{
            box-shadow: 0 0 30px 0 rgba(10, 25, 45, 0.15);
        }

        @include smaller-than-1024{
            width: 100% !important;
            margin-right: 0;
            margin-bottom: $spacing * 2;
        }

        .tile-content{
            min-height: calc(100% - 78px);
            padding: $spacing*2;

            .tile-content-header{
                display: flex;
                justify-content: space-between;

                .left-column{
                    display: flex;
                    justify-content: flex-start;
                    text-align: left;

                    .status{

                    }
                }

                .right-column{
                    text-align: right;
                }
            }

            .tile-content-body{
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                min-height: calc(100% - 45px);

            }


        }

        .tile-actions{
            display: flex;
            justify-content: space-between;
            align-items: center;
            //border-top: 1px solid $tile_border;
            padding: $spacing*2;

            button{
                max-width: 40%;
            }

            .MuiSvgIcon-root{
                cursor: pointer;
            }

            .MuiSvgIcon-root+.MuiSvgIcon-root{
                margin-left: $spacing*2;
            }
        }

        .progressbar{
            //position: absolute;
            //bottom: 0;
            //left: 0;
            margin-top: 8px;
            height: 6px;
            width: 100%;
            border-bottom-left-radius: 6px;
            border-bottom-right-radius: 6px;
            //background-color: $additional_grey_4;
        }
    }


}

.pointer{
    cursor: pointer;
}

.full-width{
    width: 100%;
}

.align-center{
    text-align: center;
}

.align-right{
    text-align: right;
}

.align-left{
    text-align: left;
}

.align-middle{
    vertical-align: middle;
}

.align-top{
    vertical-align: top;
}

.align-sub{
    vertical-align: sub;
}

.tab-body{
    padding: $spacing*2;

    @include smaller-than-1024{
        padding: 0;
    }
}

.image-circle{
    height: 60px;
    width: 60px;
    min-width: 60px;

    &.small{
        height: 40px;
        width: 40px;
        min-width: 40px;
    }

    &.large{
        height: 76px;
        width: 76px;
        min-width: 76px;
    }

    img{
        height: 100%;
        width: 100%;
        object-fit: cover;
        border-radius: 60px;
    }
}

.no-text-wrap{
    flex: 0 0 auto;
}

.flex-align-vertical-middle{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.flex-align-horizontal-middle{
    display: flex;
    align-items: center;
}
.margin-auto{
    margin: 0 auto;
}

.stats{
    display: flex;

    .stat+.stat{
        margin-left: $spacing*3;
    }
}

.display-block{
    display: block;
}

.display-none{
    display: none;
}

.card{
    border-radius: 6px;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    box-shadow: 0 0 30px 0 rgba(10, 25, 45, 0.07);
    padding: $spacing*2;
    height: fit-content;

    &.card-large {
        padding: $spacing * 3 $spacing * 4;

        @include smaller-than-1024 {
            padding: $spacing * 2;
        }
    }
}

.flex-row{
    display: flex;
    flex-direction: row;
}

.flex-column{
    display: flex;
    flex-direction: column-reverse;

    button+button{
    margin-bottom: $spacing*1;
    }

}

.space-between{
    display: flex;
    justify-content: space-between;
}

.price-row{
    display: flex;
    justify-content: space-between;
}


.warning{
    padding: $spacing*2;
    background-color: $light_neon;
    border-radius: 6px;
}

.badge{
    padding: 2px $spacing;
    border-radius: 4px;
    margin-left: $spacing;
    font-size: 12px;
    font-weight: 500;
    white-space: nowrap;

    &.new{
        background-color: $light_neon;
        color: $dark_neon;
    }

    &.error{
        background-color: $system_error_background;
        color: $system_error;
    }

    &.default{
        background-color: $gray_5;
        color: $gray_3;
    }
}

.pre-wrap{
    white-space: pre-wrap;
}

#notistack-snackbar{
    a{
        text-decoration: underline;
    }
}

.payment-method{
    display: flex;
    justify-content: space-between;
    border-radius: 4px;
    padding: $spacing*2;
    border: 1px solid transparent;
    width: 530px;
    max-width: 100%;
    border-bottom: 1px solid $additional_grey_4;

    &:last-child{
       border: 0;
    }

    .cover-bage{
        display: none;
    }

    &:hover{
        //background-color: $additional_grey_5;
        //color: $additional_grey_1;

        .right-column{
            align-items: flex-start;

            .MuiSvgIcon-root{
                cursor: pointer;
            }
        }
    }

    &.cover{
        //border: 1px solid $primary_neon;
        //box-sizing: border-box;

        .cover-bage{
            display: block;
        }

        &:hover{
            background-color: inherit;

            .right-column{
                button{
                    //display: none;
                }
            }
        }
    }

    .left-column{
        display: flex;
        align-items: flex-start;

        .photo{
            width: 40px;
            border-radius: 4px;

            img{
                width: 100%;
                object-fit: cover;
                display: inline-block;
            }
        }
    }

    .right-column{
        display: flex;

        button{
            //display: none;
            background-color: $primary_white;
            border-color: $additional_grey_4;
        }

        button+button{
            margin-left: $spacing*2;
        }
    }
}

.accordion-row{
    border-top: 1px solid $additional_grey_4;
    padding: 2*$spacing 0;
}


.location-icon{
    height: 20px !important;
    width: 20px !important;
}

.truncate {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.blacklink {
    color: $primary_black;

    &:hover{

    }
}

