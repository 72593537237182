.GigListPage {
    width: 100%;
    padding-left: 20px;

    .content {
        .navigation {
            text-align: right;
        }

        .list {
            margin-top: 10px;
            background-color: #FFF;
            border: 1px solid #e0e0e0;
            min-height: 100px;
            border-radius: 8px;
        }
    }
}

.drafts {
    .filters {
        .select {
            border: 1px solid #DFE4ED;
            border-radius: 16px;
            padding: 5px 10px 5px 16px;

            .MuiFormControl-root {
                .Mui-Focused {
                    .MuiInput-root {
                        .MuiInputBase-input {
                            background: #FFFFFF;
                        }
                    }
                }

                .MuiInput-root {
                    &:before {
                        border-bottom: 0;
                    }

                    &:after {
                        border-bottom: 0;
                    }

                    .MuiInputBase-input {
                        padding: 0 10px;
                        margin-right: 16px;
                    }
                }
            }
        }
    }
}
