@import "src/static/styles/figma-variables";
@import "src/static/styles/breakpoints";

.footer{
    background-color: $additional_grey_5;
    padding: $spacing*5 $spacing*10 $spacing*2 $spacing*10;
    flex: 1;
    z-index: 200;

    @include smaller-than-1024{
            padding: $spacing*5 $spacing*2 $spacing*5 $spacing*3;
    }

    .menu{
        justify-content: space-between;
        display: flex;
        flex-wrap: wrap;

        @include smaller-than-1024{
            flex-direction: column;
        }

        >div{
            max-width: 222px;
        }
    }
}
