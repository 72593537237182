@import "src/static/styles/breakpoints";
@import "src/static/styles/figma-variables";

.integration {
    display: flex;
    flex-direction: column;
    margin-top: $spacing*2;

    @include smaller-than-1024 {
        flex-direction: column;
    }

    &-staff {
        display: flex;
        flex-direction: column;
        padding: 16px;
    }

    &-select {
        display: flex;
        flex-direction: column;
    }

    .avatar {
        img {
            width: 40px;
            height: 40px;
            border-radius: 30px;
            object-fit: cover;
        }
    }

    .integration-form {
        .token-input {
            display: flex;
            align-items: center;

            @include smaller-than-1024 {
                flex-direction: column;
                align-items: unset;
            }

            &-text {
                width: 450px;
                @include smaller-than-1024 {
                    width: 100%;
                }
            }

            &-button {
                width: 120px;
                margin-top: 2px;
                margin-right: $spacing;

                @include smaller-than-1024 {
                    width: 100%;
                    margin: 0;
                    margin-top: 0px;
                }
            }
        }

        .token-is-set {
            display: flex;
            align-items: center;

            &-button {
                margin-top: 2px;
                margin-left: $spacing*2;

                @include smaller-than-1024 {
                    width: 100%;
                    margin: 0;
                    margin-top: 0px;
                }
            }

        }


    }

    .left-side-staff {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 30%
    }

    .right-side-gigsler{
        display: flex;
        align-items: center;
        width: 30%
    }

    .binding-button {
        width: 50px;
        margin-bottom: $spacing*2;
        margin-left: $spacing;
    }

    .binding-button-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }

    .width30 {
        width: 30%;

    }

}




