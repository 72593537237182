@import "src/static/styles/figma-variables";
@import "src/static/styles/breakpoints";

.page>.profile {
    display: flex;

    .overlay-loader {
        width: unset;
        @include  smaller-than-1024{
            width:100%;
        }
    }

    .content {
        margin: 10px;
        padding: 10px;
        border-radius: 8px;
        background-color: #FFF;
    }

   }
