:root {
    /* MAIN */
    --color-main: #1890ff;

    /* TEXT */
    --color-text-main: #333;
    --color-text-avatar: #FFF;
    --color-text-description: #555;
    --color-text-date: #888;
    --color-text-danger: #ff4d4f;

    /* LINK */
    --color-link-main: #1974c9;
    --color-link-hover: #1890ff88;
    --color-link-active: #1890ff;

    /* BORDER */
    --color-border-main: #1890ff;
    --color-border-danger: #ff4d4f;

    /* BACKGROUND */
    --color-background-main: #1890ff08;
    --color-background-main-2: #1890ff20;
    --color-background-main-4: #1890ff40;
    --color-background-main-6: #1890ff60;
    --color-background-danger: #fff1f1;
    --color-background-success: #80ff80;
    --color-background-white: #FFF;
    --color-background-status-new: #b6dcff;
    --color-background-status-cancel: #ffafb0;

    /* SHADOW */
    --color-shadow-main: #00000044;

    /* SCROLLBAR */
    --color-scrollbar: #dfe4ed;
}