@import "src/static/styles/figma-variables";

.restaurant.tile{
    .restaurant-image-wrapper{
        height: 180px;
        width: 100%;

        img{
            height: 100%;
            width: 100%;
            object-fit: cover;
            border-top-left-radius: 6px;
            border-top-right-radius: 6px;
        }
    }

    .info{
        padding: $spacing*2;
    }
}
