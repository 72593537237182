@import "src/static/styles/figma-variables";

.skill-row{
        display: flex;
        justify-content: space-between;
        margin-bottom: $spacing;
        margin-top: $spacing;
}

.skill-row:hover{
    color: $primary_black;
}

.page>.type-list{
    display: flex;

    .content {
        margin: 10px;
        padding: 10px;
        border-radius: 8px;
        background-color: #FFF;
    }
}

.type-wrapper {
    min-width: 500px;
    width: 500px;
}


