@import "src/static/styles/breakpoints";
@import "src/static/styles/figma-variables";

.history-contract {
    position: relative;
    //border: 1px solid $tile_border;
    border-radius: 6px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: 0 0 30px 0 rgba(10, 25, 45, 0.07);
    min-width: 800px;
    cursor: pointer;

    &:hover{
        box-shadow: 0 0 30px 0 rgba(10, 25, 45, 0.15);
    }

    @include smaller-than-1024{
        width: 100% !important;
        min-width: unset;
        margin-right: 0;
    }

    .tile-content{
        min-height: calc(100% - 78px);
        padding: $spacing*2;

        .tile-content-header{
            display: flex;
            justify-content: space-between;

            @include smaller-than-1024 {
                flex-direction: column;
            }

            .left-column{
                display: flex;
                justify-content: flex-start;
                text-align: left;

                .status{
                    .name-status {
                        display: flex;
                        @include smaller-than-1024 {
                            flex-direction: column;
                            padding-bottom:4px;

                            .badge:first-child {
                                margin:0;
                            }
                        }
                    }
                }
            }

            .right-column{
                text-align: right;
                display: flex;
            }
        }

        .tile-content-body{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            min-height: calc(100% - 45px);

            .stats{
                display: flex;

                .stat+.stat{
                    margin-left: $spacing*3;
                }
            }
        }


    }

    .tile-actions{
        display: flex;
        justify-content: space-between;
        align-items: center;
        //border-top: 1px solid $tile_border;
        padding: $spacing*2;

        button{
            max-width: 40%;
        }

        .MuiSvgIcon-root{
            cursor: pointer;
        }

        .MuiSvgIcon-root+.MuiSvgIcon-root{
            margin-left: $spacing*2;
        }
    }

    .mobile-buttons{
        border-top: 1px solid $additional_grey_4;
        padding: 2*$spacing;

        &:empty{
            padding: 0;
            border-top: none;
        }
    }

}
