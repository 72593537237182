.overlay-loader{
    position: relative;
    height: fit-content;
    width: 100%;

    .loader{
        z-index: 100;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(255, 255, 255, 0.8);
    }
}

